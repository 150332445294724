@font-face {
  font-family: 'Sora';
  src: url('./assets/fonts/Sora-Regular.woff2') format('woff2'),
    url('./assets/fonts/Sora-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sora';
  src: url('./assets/fonts/Sora-SemiBold.woff2') format('woff2'),
    url('./assets/fonts/Sora-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sora';
  src: url('./assets/fonts/Sora-Light.woff2') format('woff2'),
    url('./assets/fonts/Sora-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sora';
  src: url('./assets/fonts/Sora-Bold.woff2') format('woff2'),
    url('./assets/fonts/Sora-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sora';
  src: url('./assets/fonts/Sora-Medium.woff2') format('woff2'),
    url('./assets/fonts/Sora-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/HelveticaNeue-Bold.woff2') format('woff2'),
    url('./assets/fonts/HelveticaNeue-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/HelveticaNeue-Light.woff2') format('woff2'),
    url('./assets/fonts/HelveticaNeue-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/HelveticaNeue-Medium.woff2') format('woff2'),
    url('./assets/fonts/HelveticaNeue-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}


* {
  padding: 0;
  margin: 0;
}

body {
  font-family: 'Helvetica Neue';
}

.container-width {
  max-width: 1520px;
  margin: 0 auto;
  width: 100%;
  position: relative;
}

.wrapper {
  padding: 40px 0;
  min-height: 100vh;
  background-image: url('./assets/images/main-bg.png');
  background-color: #040908;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
}

/* headings */
h1,
h2,
h3 {
  font-family: 'Sora';
  font-weight: 700;
}

h1 {
  font-size: 50px;
}

h2 {
  font-size: 25px;
}

h3 {
  font-size: 20px;
}

p {
  font-size: 17px;
  font-weight: 300;
  line-height: 25px;
}

.text-white {
  color: white;
}

.text-green {
  color: #3DE085;
}

.text-white-light {
  opacity: 80%;
}

/* header */
.logo {
  width: 140px;
}

.logo img {
  width: 100%;
  height: 100%;
}

#header .navbar-wrapper {
  background-color: #4d5151;
  border-radius: 99px;
  padding: 16px;
}

.header-list-area,
.header-list,
.header-list ul {
  width: 100%;
}

.header-list ul {
  list-style: none;
}

.header-list ul li:not(:last-child) {
  margin-right: 40px;
}

.header-list ul li a,
.header-drop a {
  color: white;
  font-weight: 300;
  font-family: 'Helvetica Neue';
  font-size: 18px;
  text-decoration: none;
  transition: all ease-in-out 0.5s;
}

.header-drop a {
  padding: 0;
}

.header-drop .dropdown-menu.show {
  background: #4d5151;
}

.header-drop .dropdown-menu.show a {
  padding: 4px 10px;
  font-size: 16px;
  margin-right: 0;
}

.header-drop .dropdown-menu.show a:hover {
  background: linear-gradient(to right, #1e866b, #35b663);
  color: #ffff;
}

.header-list ul li a:hover,
.header-drop a:hover {
  color: #3DE085;
}

#header .navbar-nav .nav-link.active,
#header .navbar-nav .nav-link.show,
#header .nav-link:focus {
  color: #3DE085;
}


.header-list ul li.active a {
  color: #3DE085;
}

.header-wallet-btn {
  height: 44px;
  min-width: 150px;
}

.header-wallet-btn .wallet-adapter-dropdown {
  width: 100%;
  height: 100%;
}

.header-wallet-btn button,
.btn-green-gradient,
.home-btn button,
.toggle-home-btn button {
  background: linear-gradient(to right, #1e866b, #35b663);
  border-radius: 99px;
  height: 100%;
  width: 100%;
  padding: 0 24px;
  font-size: 17px;
  font-weight: 500;
  color: white;
  border: none;
  cursor: pointer;
}

.home-btn button {
  min-width: 160px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle-home-btn button {
  min-width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
}

.header-wallet-btn button:hover,
.btn-green-gradient:hover,
.toggle-home-btn button:hover,
.tabs-content-area-info .tab.active:hover {
  background: linear-gradient(to right, #35b663, #1e866b);
}


/* body conetnt */
.main-padding-area {
  padding-top: 80px;
}

/* hello stake */
.hello-stake-heading {
  margin-bottom: 55px;
}

.hello-stake-heading h1 {
  margin-bottom: 5px;
  line-height: 45px;
}

.countdown-timer {
  background-color: #4d5151;
  padding: 24px;
  border-radius: 20px;

}

.countdown-timer input {
  width: 100%;
}

.countdown-timer-info {
  border-bottom: 1px solid #ffffff45;
  padding-bottom: 20px;
  margin-bottom: 25px;
}

.countdown-timer-info h3 {
  margin-bottom: 6px;
}

.countdown-timer span {
  font-size: 40px;
  font-weight: 700;
  font-family: 'Sora';
}

.countdown-timer .stake-input {
  background: transparent;
  border: none;
  box-shadow: unset;
  font-size: 50px;
  display: block;
  color: #ffffffc7;
  font-weight: bold;
  font-family: 'Sora';
}

.countdown-timer .stake-input:focus-visible {
  outline: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.stake-container .btn-green-gradient {
  height: 44px;
}

button:disabled,
button:disabled:hover {
  background: #FFFFFF1A;
  color: #FFFFFF80;
  cursor: no-drop;
}

.line-spacer {
  border-bottom: 1px solid #ffffff45;
  padding-top: 30px;
  margin-bottom: 30px
}

.stake-table .table-responsive {
  border: 1px solid #5A5D5D;
  border-radius: 20px;
}

.stake-table table {
  width: 100%;
}

.stake-table table th,
.stake-table table td {
  font-size: 17px;
  color: white;
  padding: 20px;
  border: 1px solid #5A5D5D;
}

.stake-table table th {
  font-weight: 700;
  font-family: 'Sora';
  border-top: none;
  background-color: #5A5D5D80;
  border-radius: 0;
}

.stake-table table td {
  font-weight: 500;
}

.stake-table table tr th:first-child,
.stake-table table tr td:first-child {
  border-left: none;
}

.stake-table table tr th:last-child,
.stake-table table tr td:last-child {
  border-right: none;
}

.small-container {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
}

.solana-link {
  color: white;
  /* Set link color */
  text-decoration: none;
  /* Remove underline */
  font-weight: bold;
  /* Make the text bold */
  font-size: 16px;
  /* Set font size */
  transition: color 0.3s ease;
  /* Smooth transition for color */
}

.solana-link:hover {
  color: #006fa6;
  /* Change color on hover */
}

.tabs-content-area-info {
  display: inline-flex;
  border: 1px solid #ffffff9e;
  border-radius: 99px;
  padding: 6px;
}

.tabs-content-area-info .tab {
  border-radius: 99px;
  font-size: 17px;
  font-weight: 500;
  color: white;
  border: none;
  cursor: pointer;
  padding: 0 24px;
  height: 44px;
  background-color: transparent;
}

.tabs-content-area-info .tab.active {
  background: linear-gradient(to right, #1e866b, #35b663);
}

.tabs-content-wrapper {
  border-radius: 20px;
  background-color: #4d5151;
  padding: 20px;
  border: 1px solid #ffffff75;
}

.tabs-content-wrapper .formGroup {
  margin-bottom: 10px;
}

.tabs-content-wrapper label {
  font-size: 17px;
  font-weight: 500;
  color: white;
  display: block;
  margin-bottom: 10px;
}

.tabs-content-wrapper input,
.tabs-content-wrapper select {
  background: transparent;
  border: 1px solid #ffffffa8;
  width: 100%;
  border-radius: 99px;
  color: #ffffffc7;
  font-size: 17px;
  font-weight: 500;
  padding: 10px 14px;
  height: 44px;
}

.tabs-content-wrapper input:focus-visible,
.tabs-content-wrapper select:focus-visible {
  outline: none;
}

.tabs-content-wrapper select option {
  background-color: #191e1e;
  color: white;
}

.tabs-content-wrapper .checkbox {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff7d;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  padding: 0;
}

.tabs-content-wrapper .checkbox:checked {
  background-color: #fff;
  border-color: #fff;
}

.tabs-content-wrapper .checkbox:checked::before {
  content: '✔';
  position: absolute;
  top: 55%;
  transform: translate(-50%, -50%);
  left: 50%;
  font-size: 16px;
  color: black;
}

.form-btn-wrapper button:not(:last-child) {
  margin-bottom: 12px;
}

.stack-tooltip-content {
  background-color: #191e1e;
  width: 280px;
  z-index: 1;
  padding: 16px;
  border-radius: 8px;
  bottom: 30px;
  right: 0;
  display: none;
}

.info-circle:hover .stack-tooltip-content {
  display: block;
}

.stack-tooltip-content .countdown-timer-info {
  padding-bottom: 6px;
  margin-bottom: 12px;
}

.stack-tooltip-content .countdown-timer-info h3,
.stack-tooltip-content p {
  font-size: 12px;
}

.stack-tooltip-content p {
  line-height: 18px;
  margin-bottom: 0;
}

.vault-detail-cards {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vault-detail-cards .header-wallet-btn {
  display: inline-flex;
}

.vault-detail-cards .header-wallet-btn button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.stake-checkbox-btn label {
  font-size: 17px;
  color: #fff;
}

.countdown-timer input[type=checkbox] {
  accent-color: #3DE085;
}

/* toggle-btn */
/* .form-toggle-switch{display: flex;justify-content: center;align-items: center;} */
.form-toggle-switch .form-switch {
  font-size: 30px;
  padding: 0;
}

.form-toggle-switch .form-switch .form-check-input:focus,
.form-switch .form-check-input {
  background-image: url('../src/assets/images/svg/toggle-btn.svg');
}

.form-toggle-switch .form-switch .form-check-input:checked {
  background-image: url('../src/assets/images/svg/toggle-w-btn.svg');
}

.form-toggle-switch .form-check-input {
  background-color: transparent;
  border: 4px solid #3DE085;
}

.form-toggle-switch .form-check-input:focus {
  box-shadow: none;
}

.counter-timer-content h3 {
  margin-right: 65px;
}



@media screen and (max-width: 1600px) {
  .container-width {
    max-width: 1320px;
  }

  h1,
  .countdown-timer span,
  .countdown-timer .stake-input {
    font-size: 42px;
  }

  .main-padding-area {
    padding-top: 70px;
  }

  .countdown-timer {
    padding: 24px 24px 16px;
  }

  .hello-stake-heading {
    margin-bottom: 44px;
  }

  .stake-table table th,
  .stake-table table td {
    font-size: 16px;
    padding: 15px;
  }

  .form-toggle-switch .form-switch {
    font-size: 25px;
  }
}

@media screen and (max-width: 1399px) {
  .container-width {
    max-width: 1140px;
  }

  h1,
  .countdown-timer span,
  .countdown-timer .stake-input {
    font-size: 36px;
  }

  h3 {
    font-size: 18px;
  }

  p {
    font-size: 16px;
  }

  .wrapper {
    padding: 30px 0;
  }

  #header .navbar-wrapper {
    padding: 12px 16px;
  }

  .logo {
    width: 120px;
  }

  .header-list ul li a,
  .header-wallet-btn button,
  .btn-green-gradient,
  .header-drop a,
  .toggle-home-btn button {
    font-size: 16px;
  }

  .main-padding-area {
    padding-top: 55px;
  }

  .hello-stake-heading {
    margin-bottom: 40px;
  }

  .countdown-timer {
    padding: 24px 20px 16px;
  }

  .countdown-timer-info {
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  .stake-table table th,
  .stake-table table td {
    font-size: 14px;
    padding: 12px;
  }

  .tabs-content-wrapper {
    padding: 20px 15px;
  }

  .tabs-content-wrapper label {
    font-size: 14px;
  }

  .header-drop .dropdown-menu.show a {
    font-size: 14px;
  }

  .form-toggle-switch .form-switch {
    font-size: 20px;
  }
}

@media screen and (max-width: 1199px) {
  .container-width {
    max-width: 960px;
  }

  h3 {
    font-size: 16px;
  }

  p {
    font-size: 14px;
    line-height: 22px;
  }

  .wrapper {
    padding: 25px 0;
  }

  #header .navbar-wrapper {
    padding: 10px 16px;
  }

  .logo {
    width: 100px;
  }

  .header-wallet-btn {
    height: 40px;
    min-width: 130px;
  }

  .header-wallet-btn button,
  .btn-green-gradient,
  .toggle-home-btn button {
    padding: 0 20px;
  }

  .header-list ul li a,
  .header-wallet-btn button,
  .btn-green-gradient,
  .tabs-content-wrapper input,
  .tabs-content-wrapper select,
  .header-drop a,
  .toggle-home-btn button {
    font-size: 14px;
  }

  .header-list ul li:not(:last-child) {
    margin-right: 30px;
  }

  .main-padding-area {
    padding-top: 45px;
  }

  h1,
  .countdown-timer span,
  .countdown-timer .stake-input {
    font-size: 30px;
  }

  .hello-stake-heading {
    margin-bottom: 30px;
  }

  .hello-stake-heading h1 {
    margin-bottom: 5px;
    line-height: 25px;
  }

  .countdown-timer {
    padding: 20px 16px 10px;
  }

  .tabs-content-area-info .tab {
    font-size: 14px;
    padding: 0 20px;
    height: 38px;
  }

  .stake-container .btn-green-gradient,
  .tabs-content-wrapper input,
  .tabs-content-wrapper select {
    height: 40px;
  }

  .stake-table table th,
  .stake-table table td {
    font-size: 13px;
    white-space: nowrap;
  }

  .header-drop .dropdown-menu.show a {
    font-size: 12px;
  }

}

@media screen and (max-width: 991px) {
  .container-width {
    max-width: 720px;
  }

  .navbar-toggler {
    width: 32px;
    height: 32px;
    border: 2px solid white;
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 20px;
  }

  .navbar-toggler:focus {
    box-shadow: unset;
  }

  .navbar-toggler img {
    width: 100%;
    height: 100%;
  }

  .header-wallet-btn {
    margin-right: 46px;
  }

  .header-list-wrapper {
    position: absolute;
    left: 0;
    top: 65px;
    width: 100%;
    z-index: 99;
  }

  .header-list.navbar-nav {
    width: 100%;
    background-color: #2b2f31;
    border-radius: 3px;
    padding: 5px 20px;
    margin-left: auto;
    border-radius: 20px;
  }

  .header-list ul li:not(:last-child) {
    margin: 0;
  }

  .header-list ul li a {
    padding: 10px 0;
    display: block;
  }

  .header-drop a {
    padding: 10px 0;
  }

  .main-padding-area {
    padding-top: 40px;
  }

  h1,
  .countdown-timer span,
  .countdown-timer .stake-input {
    font-size: 26px;
  }

  .hello-stake-heading {
    margin-bottom: 24px;
  }

  .header-drop .dropdown-menu.show {
    max-width: 160px;
  }

  .vault-detail-cards {
    padding: 20px 15px;
  }

}

@media screen and (max-width: 767px) {
  .container-width {
    max-width: 540px;
  }

  .vault-detail-cards {
    display: block;
    text-align: center;
  }

}

@media screen and (max-width: 576px) {

  .container-width,
  .small-container {
    max-width: 95%;
    padding: 0 15px;
  }

  .wrapper {
    padding: 20px 0;
  }

  #header .navbar-wrapper {
    padding: 8px 16px;
  }

  .logo {
    width: 80px;
  }

  .main-padding-area {
    padding-top: 30px;
  }

  .stake-container .container {
    padding: 0;
  }
}

@media (max-width:480px) {
  .vault-detail-cards .header-wallet-btn {
    display: block;
    margin-bottom: 15px;
  }

  .vault-detail-cards .header-wallet-btn:last-child {
    margin-bottom: 0;
  }

}

/* -------------------------------------------------------------- Loader CSS ------------------------------------------------------------------------- */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes gradient-move {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  /* Slightly more opaque */
  z-index: 1000;
}

.loader {
  border: 20px solid transparent;
  border-top: 20px solid #1e866b;
  /* Set a color for the spinner */
  border-radius: 50%;
  width: 220px;
  height: 220px;
  animation: spin 1.5s linear infinite;
  position: relative;
}

.loader::before {
  content: '';
  position: absolute;
  top: -20px;
  left: -20px;
  right: -20px;
  bottom: -20px;
  border-radius: 50%;
  background: linear-gradient(45deg, #1e866b, #35b663, #1e866b);
  background-size: 200% 200%;
  z-index: -1;
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  mask-composite: exclude;
  animation: gradient-move 3s ease infinite;
}


.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-button {
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  background: linear-gradient(to right, #1e866b, #35b663);
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.pagination-button:hover {
  background: linear-gradient(to left, #1e866b, #35b663);
  ;
}

.pagination-button:disabled {
  background-color: #d6d6d6;
  color: #999;
  cursor: not-allowed;
}

.pagination-text {
  margin: 0 10px;
  font-size: 14px;
  color: #fff;
}








.stake-radio-btn-wrapper .radio-group {
  border-radius: 12px;
  display: flex;
  gap: 10px;
  justify-content: end;
}

.stake-radio-btn-wrapper .radio-input {
  display: none;
}

.stake-radio-btn-wrapper .radio-label {
  background-color: #5f6363;
  padding: 10px 15px;
  border-radius: 10px;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 80px;
}

.token-balance {
  font-size: 14px;
  /* Adjust as needed */
  margin: 0;
  /* Ensure no extra margin is added */
}

.stake-radio-btn-wrapper .radio-label:hover {
  background: linear-gradient(to right, #1e866b, #35b663);
}

.radio-input:checked+.radio-label {
  background: linear-gradient(to right, #1e866b, #35b663);
  color: #ffffff;
}

.max-btn {
  background: linear-gradient(to right, #35b663, #1e866b);
  border-color: transparent;
}

.max-btn:hover {
  background: linear-gradient(to left, #35b663, #1e866b);

  border-color: transparent;
}